.loadingText {
  font-family: Arial;
  font-weight: 900;
  font-size: 10vh;
}

.rotatingImage {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
